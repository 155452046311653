
(function ($, _) {
  'use strict'

  _.mixin({
    'interpret': function (value) {
      if (value === null || value === undefined) {
        return ''
      }

      return String(value)
    },

    'interpretNumber': function (value, strict = false) {
      if (value === null || value === undefined) {
        return 0
      }

      if (!strict) {
        value = parseFloat(value)
      }

      if (_.isNumeric(value)) {
        return value
      }

      return 0
    },

    'interpretDate': function (value) {
      if (_.isMoment(value)) {
        return value
      }

      if (_.isDate(value)) {
        return moment(value)
      }

      if (value === null || value === undefined) {
        return undefined
      }

      const date = moment(value)

      if (!date.isValid()) {
        return undefined
      }

      return date
    },

    'formatNumber': function (value, options) {
      options = _.cloneMerge({
        'nan': 'NaN',
        'delimiter': ',',
        'separator': '.',
        'precision': undefined
      }, options)

      if (!_.isNumeric(value)) {
        return options.nan
      }

      if (!_.isUndefined(options.precision)) {
        value = value.toFixed(options.precision)
      }

      const split = String(value).split('.')

      if (options.delimiter) {
        split[0] = split[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + options.delimiter)
      }

      return split.join(options.separator)
    },

    'formatDate': function (value, options) {
      var date

      options = _.cloneMerge({
        'notADate': 'N/A',
        'format': 'YYYY-MM-DD'
      }, options)

      date = _.interpretDate(value)

      if (date) {
        return date.format(options.format)
      }

      return options.notADate
    },

    'formatTime': function (value, options) {
      return _.formatDate(value, _.cloneMerge({
        'format': 'hh:mm A'
      }, options))
    },

    'relativeTime': function (value, options) {
      var date

      options = _.cloneMerge({
        'notADate': 'N/A',
        'format': 'YYYY-MM-DD',
        'suffix': true
      }, options)

      date = _.interpretDate(value)

      if (date) {
        if (_.has(options, 'relativeTo')) {
          return date.from(options.relativeTo, !options.suffix)
        }

        return date.fromNow(!options.suffix)
      }

      return options.notADate
    },

    'shortenNumber': (function () {
      var SUFFIXES = [
        '', 'k', 'm', 'b'
      ]

      return function (value, options) {
        options = _.cloneMerge({
          'nan': 'NaN'
        }, options)

        if (!_.isNumeric(value)) {
          return options.nan
        }

        if (value < 1000) {
          return String(value)
        }

        const exponent = _.min([Math.floor(Math.log(value) / Math.log(1000)), SUFFIXES.length - 1]),
          unit = Math.pow(1000, exponent),
          number = value / unit

        options.precision = _.divmod(value, unit)[1] / unit >= 0.1 ? 1 : 0

        return _.formatNumber(number, options) + SUFFIXES[exponent]
      }
    }()),

    'numberToCurrency': function (value, options) {
      options = _.cloneMerge({
        'template': '$%s',
        'nan': 'N/A'
      }, options)

      return s.sprintf(options.template, _.formatNumber(value, options))
    },

    'numberToShortCurrency': function (value, options) {
      options = _.cloneMerge({
        'template': '$%s',
        'nan': 'N/A'
      }, options)

      return s.sprintf(options.template, _.shortenNumber(value, options))
    },

    'currencyToNumber': function (currency) {
      if (_.isNumber(currency)) {
        return currency
      }

      currency = s.strip(_.interpret(currency))

      if (_.present(currency)) {
        currency = s.lstrip(currency, '$')
        currency = currency.replace(/,/g, '')

        return _.interpretNumber(currency)
      }

      return 0
    },

    'numberToPercentage': function (value, options) {
      var percentage

      options = _.cloneMerge({
        'allowLessThanZero': false,
        'allowGreaterThanOneHundred': false
      }, options)

      if (_.present(value)) {
        value = s.strip(value)
        value = value.replace(/,/g, '')
        value = _.interpretNumber(s.rstrip(value, '%'))

        if (!_.isNumber(value)) {
          percentage = '0%'
        } else if (value < 0 && !options.allowLessThanZero) {
          percentage = '0%'
        } else if (value > 100 && !options.allowGreaterThanOneHundred) {
          percentage = '100%'
        } else {
          percentage = value + '%'
        }

        return percentage
      }

      return '0%'
    },

    'divmod': function (value, n) {
      return [Math.floor(value / n), value % n]
    },

    'escapeQuotes': function (value) {
      return _.interpret(value).replace(/"/g, '&quot;')
    },

    // the version of this in Underscore.string is awful.
    'escapeHTML': function (value) {
      return $('<div/>').text(_.interpret(value)).html()
    },

    // the version of this in Underscore.string is also awful.
    'unescapeHTML': function (value) {
      return $('<div/>').html(_.interpret(value)).text()
    },

    'blank': function (value) {
      if (value === null || value === undefined) {
        return true
      }

      if (_.isFunction(value)) {
        return false
      }

      if (_.isRegExp(value)) {
        return false
      }

      if (_.isElement(value)) {
        return false
      }

      if (_.isArray(value)) {
        return value.length === 0
      }

      if (_.isObject(value)) {
        return _.size(value) === 0
      }

      return (/^\s*$/).test(value)
    },

    'present': function (value) {
      return !_.blank(value)
    },

    'presence': function (value) {
      if (_.present(value)) {
        return value
      }
    },

    'isJSON': function (value) {
      var str = _.interpret(value)
      if (_.blank(str)) {
        return false
      }

      str = str.replace(/\\(?:["\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@')
      str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
      str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '')
      return (/^[\],:{}\s]*$/).test(str)
    },

    'isNumeric': function (value) {
      return !isNaN(parseFloat(value)) && isFinite(value)
    },

    'isDigit': function (value) {
      return !!/^[0-9]$/.exec(value)
    },

    'isDefined': function (value) {
      return !_.isUndefined(value)
    },

    'isUUID': function (value) {
      return (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/).test(value)
    },

    'wrapArray': function (value) {
      if (_.isArray(value)) {
        return value
      }

      if (_.isArrayLike(value) && !_.isString(value)) {
        return value
      }

      if (!_.isUndefined(value) && !_.isNull(value)) {
        return [ value ]
      }

      return []
    },

    'isMoment': function (value) {
      return window.moment && moment.isMoment(value)
    },

    'parseURL': (function () {
      var regexp = new RegExp(
          '^' +
          '(?:(\\w+:)' + // protocol
          '\\/\\/)?' +
          '(?:([^\\/:@]*)(?::([^\\/:@]*))?@)?' + // user and pass
          '([^\\/:#?]+)?' + // host
          '(?::(\\d+)?)?' + // port
          '(/[^?#]*)?' + // path
          '(?:\\?([^#]*)?)?' + // search
          '(#.*)?' // hash
        ),
        fields = [null, 'protocol', 'user', 'password', 'hostname', 'port', 'pathname', 'search', 'hash']

      return function (url) {
        var components = url.match(regexp),
          retval = {}

        if (!components) {
          return {}
        }

        // Safari returns matches in RegExps in some kind of native
        // object or something, so don't use Array#each here.
        (function () {
          var i

          for (i = 0; i < components.length; i++) {
            if (components[i] && fields[i]) {
              retval[fields[i]] = components[i]
            }
          }
        }())

        retval.pathname = (function () {
          var pathname = _.interpret(retval.pathname)
          if (_.blank(pathname)) {
            return '/'
          }

          return pathname
        }())

        retval.search = _.interpret(retval.search)
        retval.port = _.interpret(retval.port)

        retval.host = (function () {
          var host = _.interpret(retval.hostname)
          if (_.present(retval.port)) {
            host += ':' + retval.port
          }

          return host
        }())

        return retval
      }
    }()),

    'parseQuery': (function () {
      function decode(string) {
        return decodeURIComponent((string || '').replace(/\+/g, ' '))
      }

      return function (queryString) {
        var params = []

        queryString = queryString.replace(/^\?/, '')

        if (queryString.length) {
          _.each(queryString.split('&'), function (param) {
            var pair = param.split('='),
              name = decode(pair.shift(), null).toString(),
              value = decode(pair.length ? pair.join('=') : null, name)

            params.push({
              'name': name,
              'value': value
            })
          })
        }

        return params
      }
    }()),

    '$w': function () {
      if (!arguments.length) {
        return []
      }

      return _.reduce(arguments, function (memo, string) {
        string = _.trim(string)
        if (string) {
          memo = memo.concat(string.split(/\s+/))
        }
        return memo
      }, [])
    },

    'humanize': function (value) {
      return s(value)
        .underscored()
        .capitalize()
        .value()
        .replace(/_+/g, ' ')
    },

    'cloneMerge': (function () {
      function callback(a, b) {
        if (_.isPlainObject(a) && _.isPlainObject(b)) {
          return _.cloneMerge(a, b)
        }

        return b
      }

      return function () {
        var args = _.toArray(arguments)
        args[0] = _.clone(args[0])
        args.push(callback)
        return _.mergeWith.apply(_, args)
      }
    }()),

    'callOrRead': function (object, method) {
      if (!_.isUndefined(object) && !_.isNull(object)) {
        if (_.isFunction(object[method])) {
          return object[method].apply(object, _.slice(_.toArray(arguments), 2))
        }

        return object[method]
      }
    },

    'coalesce': function () {
      return _.find(arguments, function (x) {
        return !_.isUndefined(x) && !_.isNull(x)
      })
    },

    'inGroupsOf': function (arr, number, fill, callback) {
      var index = 0,
        slices = [],
        padding,
        args = _.toArray(arguments)

      if (_.isFunction(args[2])) {
        fill = null
        callback = args[2]
      }

      if (fill) {
        padding = (number - arr.length % number) % number

        _.each(_.range(padding), function () {
          arr.push(fill)
        })
      }

      while (index < arr.length) {
        slices.push(arr.slice(index, index + number))

        if (callback) {
          callback(_.last(slices))
        }

        index += number
      }

      return slices
    },

    'findParam': function (value, name) {
      return _.find(value, function (param) {
        return param.name === name
      })
    },

    'filterParams': function (value, name) {
      return _.filter(value, function (param) {
        return param.name === name
      })
    },

    'atLeast': function (value, atLeast) {
      if (value < atLeast) {
        return atLeast
      }

      return value
    },

    'atMost': function (value, atMost) {
      if (value > atMost) {
        return atMost
      }

      return value
    },

    // Extensions for existing functions...

    // To allow for Google Maps' equals methods.
    'isEqual': _.wrap(_.isEqual, function (proceed, a, b, stack) {
      if (a !== undefined && _.isFunction(a.equals)) {
        return a.equals(b)
      }
      return proceed(a, b, stack)
    })
  })

  // Aliases
  _.tryIt = _.callOrRead
  _.valuesAt = _.at
  _.collect = _.map
  _.all = _.every
  _.any = _.some
}(jQuery, _))
