
window.jQuery = window.$ = require('jquery')
window.moment = require('moment')
window._ = require('lodash')
window.s = require('underscore.string')
window.ActionCable = require('@rails/actioncable')
window.ActiveStorage = require('@rails/activestorage')
window.ActiveStorage.start()

require('jquery-migrate')
require('jquery-serializejson')
require('lodash/extensions')
require('moment-timezone')
require('bootstrap-datepicker')

require('custom-event-polyfill')
require('element-closest')
require('formdata-polyfill')
require('whatwg-fetch')

jQuery.migrateTrace = false

window.SecuricyApp = window.SecuricyApp || {}
window.SecuricyApp.page = window.SecuricyApp.page || {}
window.$S = window.SecuricyApp
window.SecuricyApp.document = window.document
window.SecuricyApp.window = window
